<template>
  <div>
    <base-page-header title="Branches">
      <template #buttons>
        <div class="d-flex justify-content-end">
          <base-button title="Add Branch" action="primary" @click-btn="addBranch" />
        </div>
      </template>
    </base-page-header>
    <base-page-content>
      <template #content>
        <page-spinner v-if="ui.loading" />
        <div class="table-block" v-else>
          <table class="table">
            <thead>
              <tr>
                <th>City, State</th>
                <th>NMLS ID</th>
                <th>Phone Number</th>
                <th>Business Hours</th>
                <th>Address</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="branch in branches" :key="branch.id">
                <td>{{ branch.city }}, {{ branch.state }}</td>
                <td>{{ branch.nmlsId }}</td>
                <td>{{ branch.phoneNumber }}</td>
                <td>{{ branch.businessHours }}</td>
                <td>{{ branch.address }}</td>
                <td>
                  <button class="btn btn-outline-danger" @click="deleteBranch(branch.id)">
                    Delete
                  </button>
                  <button class="ms-2 btn btn-outline-dark" @click="editBranch(branch.id)">
                    Edit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </base-page-content>
    <transition name="component-fade" mode="out-in">
      <state-action-modal
        v-if="ui.showActionModal"
        :branch-id="ui.selectedBranch"
        :states="states"
        @close="closeModal"
      />
    </transition>
  </div>
</template>

<script>
import BaseButton from '../../components/BaseButton.vue';
import BasePageContent from "../../components/BasePageContent.vue";
import BasePageHeader from "../../components/BasePageHeader.vue";
export default {
  components: {
    BasePageHeader,
    BasePageContent,
    PageSpinner: () => import("../../components/pageSpinner.vue"),
    StateActionModal: () => import("./includes/BranchActionModal.vue"),
    BaseButton,
  },
  name: "Branches",
  data() {
    return {
      ui: {
        loading: false,
        showActionModal: false,
        selectedBranch: null,
        deleting: false,
      },
      branches: [],
      licenses: [],
      states: [],
    };
  },
  beforeMount() {
    this.getBranches();
  },
  methods: {
    getBranches() {
      this.ui.loading = true;
      this.$http.get('/api/v1/public-branches')
        .then((response) => {
          this.branches = response.data.branches
          this.branches = this.branches.map(el => {
            el.deleting = false
            return el
          });
          this.states = response.data.states
          this.ui.loading = false;
        })
    },
    editBranch(stateId) {
      this.ui.selectedBranch = stateId;
      this.ui.showActionModal = true;
    },
    deleteBranch(id) {
      if (confirm('Delete branch?')) {
        this.$http.delete(`/api/v1/public-branches/${id}`)
          .then(() => this.getBranches())
      }
    },
    addBranch() {
      this.ui.selectedBranch = null;
      this.ui.showActionModal = true;
    },
    closeModal(getState = false) {
      if (getState) {
        this.getBranches()
      }
      this.ui.showActionModal = false
    }
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-top: 12px;
  .btn {
    &.btn-primary {
      min-width: 105px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      background-color: #0057ed;
    }
  }
}
.table-block {
  .table {
    thead {
      tr {
        th {
          color: #000000;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 24px;
          opacity: 1;
          padding-left: 0;
          &:first-child {
            padding-left: 24px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding-left: 0;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 21px;
          &:first-child {
            padding-left: 24px;
          }
        }
      }
    }
  }
}
.delete-btn {
  min-width: 65px;
}
</style>
